import React from "react"
import {
  BodyContent,
  CalloutInfoSection,
  CallToAction,
  CardGroup,
  ContactSection,
  ContentWSidebar,
  Heading,
  Banner,
  Hero,
  NewHero,
  RotatingHero,
  PageTitle,
  RecentPosts,
  RtoTagline,
  TabsSection,
  TeamMemberSection,
  AdvertisingPopup,
  IdeasGallery,
  FaqDropdowns,
  CustomHeros,
} from "./pagebuilder-parts/index"
const AllLayouts = ({ layoutData, location }) => {
  const layoutType = layoutData.__typename

  /**
   * Default Component
   */
  const Default = () => (
    <div>
      In AllLayouts the mapping of this component is missing {layoutType}
    </div>
  )

  /**
   * Mapping the typenames(s) to our components
   */

  const layouts = {
    WpPage_Pagebuilder_Layouts_Hero: Hero,
    WpPage_Pagebuilder_Layouts_NewHero: NewHero,
    WpPage_Pagebuilder_Layouts_RotatingHero: RotatingHero,
    WpPage_Pagebuilder_Layouts_BodyContent: BodyContent,
    WpPage_Pagebuilder_Layouts_CalloutInfoSection: CalloutInfoSection,
    WpPage_Pagebuilder_Layouts_CallToAction: CallToAction,
    WpPage_Pagebuilder_Layouts_CardGroup: CardGroup,
    WpPage_Pagebuilder_Layouts_ContactSection: ContactSection,
    WpPage_Pagebuilder_Layouts_ContentWSidebar: ContentWSidebar,
    WpPage_Pagebuilder_Layouts_Heading2: Heading,
    WpPage_Pagebuilder_Layouts_Banner: Banner,
    WpPage_Pagebuilder_Layouts_PageTitle: PageTitle,
    WpPage_Pagebuilder_Layouts_RtoTagline: RtoTagline,
    WpPage_Pagebuilder_Layouts_TeamMemberSection: TeamMemberSection,
    WpPage_Pagebuilder_Layouts_TabsSection: TabsSection,
    WpPage_Pagebuilder_Layouts_RecentPosts: RecentPosts,
    WpPage_Pagebuilder_Layouts_AdvertisingPopup: AdvertisingPopup,
    WpPage_Pagebuilder_Layouts_IdeasGallery: IdeasGallery,
    WpPage_Pagebuilder_Layouts_FaqDropdowns: FaqDropdowns,
    WpPage_Pagebuilder_Layouts_CustomHeros: CustomHeros,
    page_default: Default,
  }

  /**
   * IF layout type is not existing in our mapping, it shows our Default layout component
   */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["page_default"]
  return <ComponentTag location={location} {...layoutData} />
}

export default AllLayouts
