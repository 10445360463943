import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import React from "react"
import AllLayouts from "../../components/AllLayouts"
import Layout from "../../components/layout"
import Seo from "../../components/Seo"
const Page = ({
  data,
  data: {
    page: {
      title,
      featuredImage,
      pageMetadata: { metaKeywords, metaDescription },
      content,
      pageBuilder,
    },
  },
  location,
}) => {
  const layouts = pageBuilder.layouts || []
  const imageUrl = getSrc(featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData)
  return (
    <Layout>
      <Seo
        title={title}
        banner={
          featuredImage && featuredImage.node !== null
            ? imageUrl
            : null
        }
        desc={metaDescription}
        keywords={metaKeywords}
      />
      {layouts.length > 0 ? (
        layouts.map((layout, index) => {
          return (
            <AllLayouts key={index} location={location} layoutData={layout} />
          )
        })
      ) : (
        <>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: `${content}` }} />
        </>
      )}
    </Layout>
  )
}


export default Page

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...PageTemplate
    }
  }
`
